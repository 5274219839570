import { useEffect, useState } from "react";
import TitlePage from "../../components/TitlePage/TitlePage";
import fetchAPI from "../../utils/fetch";
import { useNavigate, useParams } from "react-router-dom";
import ProList from "../../components/ProList/ProList";
import Loader from "../../components/Loader/Loader";
import CompetitionList from "../../components/CompetitionList/CompetitionList";
import ProBrand from "../../components/ProBrand/ProBrand";

const Pro = ({match}) => {

    const {id} = useParams();
    const [pro, setPro] = useState(null);
    const [competitions, setCompetitions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        (async () => {

            try {

                const request = await fetchAPI('GET', '/pro/get-player', {
                    proId: parseInt(id)
                });

                if(request.status !== 200) throw new Error();
                
                setPro(request.json.data.pro);
    
            }
            catch(e) {
                navigate('/');
                return;
            }

        })();

    }, [id, navigate]);

    useEffect(() => {

        setIsLoading(true);

        (async () => {

            if(!pro) return;

            try {

                const request = await fetchAPI('GET', '/competition/getall-player', {
                    filterPro: pro.proId
                });

                if(request.status !== 200) throw new Error();

                setCompetitions(request.json.data.competitions);
                setIsLoading(false);

            }
            catch(e) {
                navigate('/auth');
                return;
            }

        })();

    }, [pro, navigate]);

    return (

        pro &&

        <>
            <Loader isLoading={isLoading} />
            <br />
            <ProBrand pro={pro} />
            <CompetitionList competitions={competitions} />
        </>
    )

}

export default Pro;
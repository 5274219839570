import styled from "styled-components";
import TourViewer from "./TourViewer/TourViewer";
import FinalTourViewer from "./FinalTourViewer/FinalTourViewer";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    
    & > p.no {
        font-size:18px;
        width:90%;
        margin:10px 0;
        font-weight:bold;
        color:grey;
    }

`

const LiveViewer = (props) => {

    return (

        props.teamId && props.live && props.live.tours &&

        <StyledDiv>
            
            { props.live.tours.map((tour) => <TourViewer tour={tour} teamId={props.teamId} teamName={props.teamName} />)}
            { props.live.tours.map((tour) => <FinalTourViewer tour={tour} teamName={props.teamName} />)}

            { props.live.tours.length === 0 && (
                <p className="no">Compétition non commencée ou annulée</p>
            )}

        </StyledDiv>

    )

}

export default LiveViewer;
import styled from "styled-components";
import CompetitionBlock from "./CompetitionBlock/CompetitionBlock";
import BrandCompetition from "./BrandCompetition/BrandCompetition";

const StyledDiv = styled.div `

    justify-content:center;
    align-items:center;
    margin:20px 0 0 0;
`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const CompetitionList = (props) => {

    return (
        <StyledDiv>
            { props.brand && <BrandCompetition competition={props.brand} /> }
            { props.competitions.map((c) => (
                <CompetitionBlock competition={c} key={c.competitionId} />
            ))}
        </StyledDiv>
    );

}

export default CompetitionList;
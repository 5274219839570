import styled from "styled-components";
import PlayersBlock from "./PlayersBlock/PlayersBlock";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;
    overflow:auto;

`

const PlayersList = (props) => {

    return (

        props.players &&

        <StyledDiv>
            {props.players.map((p) => <PlayersBlock onClickPlayer={props.onClickPlayer} key={p.playerId} player={p} onRemovePlayer={props.onRemovePlayer} />)}
        </StyledDiv>

    )


}

export default PlayersList;
import styled from "styled-components";

const StyledImg = styled.img `

    position:absolute;
    bottom:40px;
    left:calc(50% - 25px);
    width:50px;

    &.notfixed {
        position:relative;
        top:20px;
        margin:0 0 40px 0;
    }

    &.textual {
        width:120px;
        left:calc(50% - 60px);
    }

`

const FixedImg = (props) => {
    
    return <StyledImg src={props.textual ? "logo_write_primary_alpha.png" : "/logo.png"} alt="Logo Neoscore" className={props.notfixed && "notfixed " + (props.textual && "textual")} />

}

export default FixedImg;
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import fetchAPI from "../utils/fetch";

const EntryPointWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:red;
    display:flex;
    flex-direction:column;
    justify-content:space-between;

    & > div.cover {
        height:100%;
        background:#f1c40f;
        display:flex;
        justify-content:center;
        align-items:center;
    }

    & > div.cover > img {
        width:80px;
    }

    & > div.links {
        position:relative;
        top:0;
        left:0;
        width:100%;
        background:green;
    }

    & > div.links > a > div {
        height:80px;
        display:flex;
        align-items:center;
        justify-content:center;
        font-size:18px;
        font-weight:bold;
    }

    & > div.links > a {
        text-decoration:none;
    }

    & > div.links > a:first-child > div {
        background:#2c3e50;
        color:white;
    }

    & > div.links > a:nth-child(2) > div {
        background:white;
        color:#2c3e50;
    }

`

const EntryPoint = () => {

    const navigate = useNavigate();

    useEffect(() => {

        (async () => {

            const request = await fetchAPI("GET", "/authenticate/check/player", {});

            if(request.status === 200) {
                navigate('/');
                return;
            }

        })();

    }, [navigate]);

    return (
        <EntryPointWrapper>

            <div className="cover">
                <img src="/logo.png" alt="logo" />
            </div>

            <div className="links">
                <Link to="/login"><div>Connexion</div></Link>
                <Link to="/register"><div>Inscription</div></Link>
            </div>

        </EntryPointWrapper>
    )

}

export default EntryPoint;
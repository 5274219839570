import styled from "styled-components";
import TeamBlock from "./TeamBlock/TeamBlock";

const StyledDiv = styled.div `

    justify-content:center;
    align-items:center;
    margin:20px 0 0 0;
`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TeamList = (props) => {

    return (
        <StyledDiv>
            { props.teams.map((t) => (
                <TeamBlock team={t} key={t.teamId} />
            ))}
        </StyledDiv>
    );

}

export default TeamList;
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:70px;
    display:flex;
    justify-content:space-around;
    align-items:center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);

    & > div.link {

        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;

        & > ion-icon {
            font-size:24px;
        }

        & > span {
            font-size:12px;
            padding:5px 0 0 0;
        }

    }

    & > div.link.active {
        color:#f1c40f;
        font-weight:bold;
    }

`

const NavigationBar = () => {

    const navigate = useNavigate();
    const location = useLocation();

    return (

        <StyledDiv>
            
            <div className={"link " + (location.pathname === "/" && "active")} onClick={() => navigate("/")}>
                { location.pathname === "/" ? <ion-icon name="home"></ion-icon> : <ion-icon name="home-outline"></ion-icon> }
                <span>Accueil</span>
            </div>

            <div className={"link " + ((location.pathname === "/inscriptions" || location.pathname.includes('live')) && "active")} onClick={() => navigate("/inscriptions")}>
                { location.pathname === "/inscriptions" ? <ion-icon name="list"></ion-icon> : <ion-icon name="list-outline"></ion-icon> }
                <span>Inscriptions</span>
            </div>

            <div className={"link " + ((location.pathname === "/pros" || location.pathname.includes('pro/')) && "active")} onClick={() => navigate("/pros")}>
                { location.pathname === "/pros" ? <ion-icon name="body"></ion-icon> : <ion-icon name="body-outline"></ion-icon> }
                <span>Organisateurs</span>
            </div>

            <div className={"link " + (location.pathname === "/profile" && "active")} onClick={() => navigate("/profile")}>
                { location.pathname === "/profile" ? <ion-icon name="person"></ion-icon> : <ion-icon name="person-outline"></ion-icon> }
                <span>Profil</span>
            </div>

        </StyledDiv>

    )

}

export default NavigationBar;
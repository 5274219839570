import styled from "styled-components";
import { useState } from "react";
import TitlePage from "../../TitlePage/TitlePage";
import PlayersList from "../PlayersList/PlayersList";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import ReturnButton from "./ReturnButton/ReturnButton";

const StyledDiv = styled.div `

    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:white;
    z-index:9999;

    & > div.tabs {
        position:relative;
        top:0;
        left:0;
        width:100%;
        height:45px;
        display:flex;
        flex-direction:row;
        justify-content:space-around;
    }

    & > div.tabs > div {
        padding:14px 0;
        font-size:14px;
        width:50%;
        text-align:center;
        background:rgba(241, 196, 15,0.2)
    }

    & > div.tabs > div.active {
        font-weight:bold;
        border-bottom:3px solid rgba(241, 196, 15,0.8);
        background:rgba(241, 196, 15,0.3)
    }

    & > div.ct {
        padding:20px;
        height:calc(100% - 45px);
        overflow:auto;
    }

    & > div.ct > p.desc {
        font-size:14px;
    }

    & > div.ct > p.nr {
        font-size:14px;
    }

`

const AddPlayer = (props) => {

    const [type, setType] = useState(0);

    return (

        <StyledDiv>
            <div className="tabs">
                <div className={type === 0 ? "active" : undefined} onClick={() => setType(0)}>Mes joueurs</div>
                <div className={type === 1 ? "active" : undefined} onClick={() => setType(1)}>Rechercher</div>
            </div>
            {
                type === 0 ?

                <div className="ct">

                    <TitlePage title="Sélectionnez" subtitle="parmi vos joueurs" />
                    <p className="desc">Vous retrouverez ici les joueurs avec lesquels vous avez déja joué. Vous pouvez rapidement les ajouter à votre équipe sans rentrer à nouveau leurs informations.</p>
                    <PlayersList players={props.friends} onClickPlayer={props.onClickPlayer} />
                    <ReturnButton title="Retour" onClick={props.close} />

                </div>

                :

                <div className="ct">

                    <TitlePage title="Rechercher" subtitle="un nouveau joueur" />
                    <p className="desc">Vous pouvez recherche un joueur par nom, numéro de téléphone ou email pour l'ajouter à votre équipe. <b>Pour cela, chacun de vos joueurs doivent posséder un compte.</b></p>
                    <Input type="text" value={props.criteria} onChange={props.handleChangeCriteria} placeholder="Nom, mail ou téléphone" />
                    <Button title="Rechercher" onClick={props.handleValidCriteria} />
                    <PlayersList players={props.results} onClickPlayer={props.onClickPlayer} />
                    { props.results && props.results.length === 0 && props.criteria && <p className="nr">Il semblerait qu'il n'y ait pas de résultats à votre recherche. Votre joueur a t'il créé et validé son compte ? Si ce n'est pas le cas, invitez-le à le faire.</p>}
                    <ReturnButton title="Retour" onClick={props.close} />
                </div>

            }
        </StyledDiv>

    )

}

export default AddPlayer;
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import fetchAPI from "../../utils/fetch";
import TitlePage from "../../components/TitlePage/TitlePage";
import LiveViewer from "../../components/LiveViewer/LiveViewer";
import RefreshButton from "../../components/RefreshButton/RefreshButton";
import Loader from "../../components/Loader/Loader";
import TeamIllustration from "../../components/TeamIllustration/TeamIllustration";


const Live = ({ match }) => {

    const {id} = useParams();
    const navigate = useNavigate();

    const [live, setLive] = useState([]);
    const [team, setTeam] = useState(null);
    const [competition, setCompetition] = useState(null);
    const [refresher, setRefresher] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        if(!id) return;

        setIsLoading(true);

        (async () => {

            try {

                const request = await fetchAPI("GET", "/team/getlive_player", {
                    teamId: id
                });

                if(request.status !== 200) {
                    navigate('/');
                    return;
                }

                setLive(request.json.data.live);
                setCompetition(request.json.data.competition);
                setTeam(request.json.data.team);
                setIsLoading(false);

            }
            catch(e) {
                navigate('/');
                setIsLoading(false);
                return;
            }

        })();

    }, [id, navigate, refresher])

    const refresh = () => {
        setRefresher(refresher+1);
    }

    return (

        <>

            <Loader isLoading={isLoading} />

            {

                live && competition && team && id &&

                <>
                    <TitlePage title="Ma compétition" subtitle="en direct" />
                    <br />
                    <TeamIllustration team={team} competition={competition} />
                    <LiveViewer live={live} teamId={id} teamName={team.name} />
                    {/* <RefreshButton onClick={refresh} /> */}
                </>

            }

        </>

    )

}

export default Live;
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import competitionModelConverter from "../../../utils/modelConverters/competition";
import fetchAPI from "../../../utils/fetch";
import Loader from "../../Loader/Loader";
import { useState } from "react";

const StyledDiv = styled.div `

    width: 98%;
    position:relative;
    background-color: white;
    margin-bottom: 18px;
    margin-top: 8px;
    margin-left:1%;
    border-radius: 12px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    & > div.info {
        margin-left: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    & > div.info > h1.title {
        font-size: 16px;
        color:black;
        margin: 2px 0 4px 0;
    }

    & > div.info > h2.pro {
        font-size:14px;
        color:#F1C40F;
        margin:0;
    }

    & > div.info > h3.details {
        font-size: 11px;
        color:grey;
        margin:10px 0 0 0;
    }

`

const CancelButton = styled.button `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:40px;
    background:#e74c3c;
    border-radius:8px;
    border:0;
    color:white;
    font-weight:bold;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TeamBlock = (props) => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    // Handlers

    const gotoLive = (id) => {
        navigate('/live/' + id)
    }

    const gotoCancel = async (id) => {

        const cf = window.confirm("Souhaitez-vous réellement supprimer votre inscription ?");

        if(!cf) return;


        setIsLoading(true);

        try {

            const request = await fetchAPI('PUT', '/team/giveup-player', {
                teamId: id
            });

            if(request.status !== 200) throw new Error();

            navigate('/confirmation-giveup');

        }
        catch(e) {
            setIsLoading(false);
            console.log(e);
        }

    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <StyledDiv onClick={() => gotoLive(props.team.teamId)}>
                <div className="info">
                    <h1 className="title">{props.team.name}</h1>
                    <h2 className="pro">{ props.team.players.map((p) => <span key={p.playerId}>{p.firstname + " "}</span>) }</h2>
                    <h3 className="details">{props.team.level.competition.name + " - " + props.team.level.name + " - " + competitionModelConverter.getFormattedDate(props.team.level.competition)}</h3>
                </div>
            </StyledDiv>
            { props.cancel && (new Date(props.team.level.competition.dte) > (new Date())) && (
                <CancelButton onClick={() => gotoCancel(props.team.teamId)}>Annuler mon inscription</CancelButton>
            )}
        </>
    );

}

export default TeamBlock;
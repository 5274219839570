import styled, {keyframes} from "styled-components";

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
`;

const StyledDiv = styled.div `

    position:fixed;
    top:0;
    left:0;
    width:100%;
    background:white;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:9999;

    & > img {
        width:80px;
        animation: ${blinkAnimation} 1s linear infinite;
    }

`

const Loader = (props) => {

    return (

        props.isLoading &&

        <StyledDiv>
            <img src="/logo.png" alt="loader" />
        </StyledDiv>

    )

}

export default Loader;
import { useEffect, useState } from "react";
import TitlePage from "../../components/TitlePage/TitlePage";
import fetchAPI from "../../utils/fetch";
import { useNavigate } from "react-router-dom";
import playerValidator from "../../utils/validators/player";
import Loader from "../../components/Loader/Loader";
import EditCredentialsForm from "../../components/EditCredentialsForm/EditCredentialsForm";

const ProfileCredentials = () => {

    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [passwordCf, setPasswordCf] = useState("");

    const [error, setError] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const onUpdatePassword = (e) => {
        setPassword(e.target.value);
        setError(null);
    }

    const onUpdatePasswordCf = (e) => {
        setPasswordCf(e.target.value);
        setError(null);
    }

    const onValidate = async () => {

        setIsLoading(true);

        try {

            if(password !== passwordCf) throw new Error("Vos mots de passe ne correspondent pas.")

            playerValidator("XXXXX", "XXXXX", "xx@xx.xx", "+33000000000", "26/07/2002", password);

            const request = await fetchAPI('PUT', '/player/updatepassword-player', {
                password: password
            });

            if(request.status !== 200) throw new Error("Une erreur est survenue.");

            navigate('/profile');
            return;

        }
        catch(e) {
            setIsLoading(false);
            setError(e.message);
        }

    }

    return (

        <>
            <TitlePage title="Modifier" subtitle="mon mot de passe" />
            <br />
            <Loader isLoading={isLoading} />
            <EditCredentialsForm error={error} password={password} passwordCf={passwordCf} onUpdatePassword={onUpdatePassword} onUpdatePasswordCf={onUpdatePasswordCf} onValidate={onValidate} />

        </>
    )

}

export default ProfileCredentials;
import styled from "styled-components";
import Input from "../Input/Input.jsx";
import Button from "../Button/Button.jsx";
import { useEffect, useState } from "react";
import playerValidator from "../../utils/validators/player.js";
import fetchAPI from "../../utils/fetch.js";
import { useNavigate } from "react-router-dom";
import SelectInput from "../SelectInput/SelectInput.jsx";
import PlayersPicker from "../PlayersPicker/PlayersPicker.jsx";
import ValidateButton from "../ValidateButton/ValidateButton.jsx";
import teamValidator from "../../utils/validators/team.js";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    margin:30px 0;

    & > p.inf {
        font-size:13px;
    }

`

const EngageForm = (props) => {

    const [levels, setLevels] = useState([]);

    useEffect(() => {

       setLevels(props.levels.map((l) => {
        return {
            value: l.levelId, 
            text: l.name
        }
       }))

    }, [props.levels])

    return (

        <StyledDiv>
            <Input type="text" placeholder="Nom d'équipe" value={props.name} onChange={props.handleChangeName} />
            <SelectInput default="Choisir un niveau" options={levels} value={props.level} onChange={props.handleChangeLevel} />
            <PlayersPicker onRemovePlayer={props.onRemovePlayer} friends={props.friends} players={props.players} results={props.results} addToPlayers={props.addToPlayers} criteria={props.criteria} handleChangeCriteria={props.handleChangeCriteria} handleValidCriteria={props.handleValidCriteria} close={props.close} />
            <ValidateButton onClick={props.handleValidate} />
        </StyledDiv>

    )

}

export default EngageForm;
import styled from "styled-components";
import PlayersList from "./PlayersList/PlayersList.jsx";
import Button from "../Button/Button.jsx";
import { useState } from "react";
import AddPlayer from "./AddPlayer/AddPlayer.jsx";

const StyledDiv = styled.div `

    width:100%;
    border:1px solid black;
    margin:20px 0;
    border-radius:12px;

    & > h4 {
        margin:10px;
        font-size:12px;
    }

    & > div.area {
        margin:0 5%;
    }

`

const PlayersPicker = (props) => {

    const [showAddPlayer, setShowAddPlayer] = useState(false);

    const handleOpenAddPlayer = () => {
        setShowAddPlayer(true);
    }

    const onClickPlayer = (player) => {
        props.addToPlayers(player);
        setShowAddPlayer(false);
    }

    const close = () => {
        setShowAddPlayer(false);
        props.close();
    }

    return (

        <>
            {showAddPlayer && <AddPlayer criteria={props.criteria} friends={props.friends} results={props.results} onClickPlayer={onClickPlayer} handleChangeCriteria={props.handleChangeCriteria} handleValidCriteria={props.handleValidCriteria} close={close} />}
            <StyledDiv>
                <h4>Ajoutez vos joueurs</h4>
                <div className="area">
                    <Button title="Ajouter un joueur" onClick={handleOpenAddPlayer} />
                </div>
                <PlayersList players={props.players} onClickPlayer={onClickPlayer} onRemovePlayer={props.onRemovePlayer} />
            </StyledDiv>
        </>

    )

}

export default PlayersPicker;
import styled from "styled-components";
import Input from "../Input/Input.jsx";
import Button from "../Button/Button.jsx";
import Error from "../ErrorBadge/ErrorBadge.jsx";
import { useState } from "react";
import fetchAPI from "../../utils/fetch";
import { useCookies } from "react-cookie";
import { useNavigate, Link } from "react-router-dom";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    margin:30px 0;

    & > a.lk {
        color:#2c3e50;
        text-decoration:none;
        font-weight:500;
        font-size:14px;
        display:block;
    }

`

const LoginForm = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [cookies, setCookies] = useCookies(["playerAuthorization"]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async () => {

        setIsLoading(true);

        if(!email || !password) {
            setError("Veuillez remplir tous les champs.");
            setIsLoading(false);
            return;
        }

        try {

            const request = await fetchAPI("POST", "/authenticate/login/player", {
                email: email,
                password: password
            });

            if(request.status !== 200) {
                setError("Identifiants incorrects ou compte non validé.");
                setIsLoading(false);
                return;
            }

            setCookies("playerAuthorization", request.json.data.token);
            navigate('/');
            return;

        }
        catch(e) {
            setIsLoading(false);
            setError("Une erreur est survenue");
        }

    }

    const onUpdateEmail = (e) => {
        setEmail(e.target.value);
        setError(null);
    }

    const onUpdatePassword = (e) => {
        setPassword(e.target.value);
        setError(null);
    }

    return (

        <StyledDiv>
            <Input type="text" placeholder="Votre email" onChange={onUpdateEmail} value={email} />
            <Input type="password" placeholder="Votre mot de passe" onChange={onUpdatePassword} value={password} />
            <Button title="Se connecter" onClick={handleLogin} />
            <Error error={error} />
            <Link to="/forgot" className="lk">J'ai oublié mon mot de passe</Link>
            <Link to="/register" className="lk">Je n'ai pas de compte</Link>
        </StyledDiv>

    )

}

export default LoginForm;
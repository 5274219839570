import styled from "styled-components";

const StyledDiv = styled.div `

    display:flex;
    flex-direction:row;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    width:95%;
    margin:5px 2.5%;
    border-radius:12px;
    align-items:center;
    justify-content:space-between;

    & > div {
        display:flex;
        flex-direction:row;
        align-items:center;
    }

    & > div > div:first-child > img {
        width:50px;
        margin:10px;
    }

    & > div > div:nth-child(2) > span {
        font-size:14px;
        font-weight:bold;
        margin:0 10px;
    }

    & > div > ion-icon {
        font-size:30px;
        margin:0 14px 0 0;
        color:#e74c3c;
    }

`

const PlayersBlock = (props) => {

    return (

        <StyledDiv onClick={() => props.onClickPlayer(props.player)}>
            <div>
                <div>
                    <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="profile" />
                </div>
                <div>
                    <span>{props.player.firstname + " " + props.player.name}</span>
                </div>
            </div>
            {

            props.onRemovePlayer

            &&
            
            <div onClick={(e) => props.onRemovePlayer(e, props.player.playerId)}>
                <ion-icon name="close-circle-outline"></ion-icon>
            </div>

            }
            
        </StyledDiv>

    )

}

export default PlayersBlock;
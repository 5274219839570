import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import fetchAPI from "../utils/fetch";

const ConfirmationValidationWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;

    & > div {
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:column;
    }

    & > div > ion-icon {
        font-size:100px;
        color:#2ecc71;
    }

    & > div > h4 {
        margin:10px;
        font-size:28px;
        color:#2ecc71;
    }

    & > div > h5 {
        margin:0;
        font-size:18px;
    }

    & > div > a {
        text-decoration:none;
        color:inherit;
    }

    & > div > a > h6 {
        margin:20px;
        font-size:16px;
        color: #f1c40f;
    }

`

const VerifyPlayer = ({ match }) => {

    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        if(!token) {
            navigate('/');
            return;
        }

        (async () => {

            try {

                const request = await fetchAPI("POST", "/authenticate/verify/player", {
                    token: token
                });

                if(request.status !== 200) {
                    navigate('/');
                    return;
                }

            }
            catch(e) {
                console.log(e);
            }

        })();

    })

    return (

        <ConfirmationValidationWrapper>

            <div>
                <ion-icon name="checkmark-done-outline"></ion-icon>
                <h4>C'est fait</h4>
                <h5>Votre email a été validé</h5>
                <Link to="/login">
                    <h6>Me connecter</h6>
                </Link>
            </div>

        </ConfirmationValidationWrapper>

    )

}

export default VerifyPlayer;
import styled from "styled-components"

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:96%;
    margin:10px 2% 20px 2%;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    border-radius:12px;
    height:100%;
    padding:10px 20px;

    & > div.header {

        position:relative;
        top:0;
        left:0;
        width:100%;
        display:flex;
        justify-content:space-between;
        margin:0 0 5px 0;

    }

    & > div.header > div {
        font-weight:600;
        font-size:14px;
    }

    & > div.header > div:last-child {
        text-align:right;
    }

    & > div.content {

        position:relative;
        top:0;
        left:0;
        width:100%;
        display:flex;
        flex-direction:column;

    }

    & > div.content > div.row {

        position:relative;
        top:0;
        left:0;
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content:space-between;

    }

    & > div.content > div.row:nth-child(odd) {
        background:#ecf0f1;
    }

    & > div.content > div.row > div {
        padding:5px;
        font-size:13px;
    }

    & > div.content > div.row > div.bolded {
        font-weight:bold;
        color:#f1c40f;
    }

`

const PouleClassement = (props) => {

    return (

        props.formattedClassement && props.teamId &&

        <StyledDiv>
            <div className="header">
                <div>Classement de la poule</div>
            </div>
            <div className="content">
                { props.formattedClassement.sort((a, b) => (a.positionInPoule - b.positionInPoule)).map((c) => (
                    <div className="row">
                        <div className={c.teamId === parseInt(props.teamId) && "bolded"}>{ c.name }</div>
                        <div className={c.teamId === parseInt(props.teamId) && "bolded"}>{ c.positionInPoule }</div>
                    </div>
                ))}
            </div>
        </StyledDiv>

    )

}

export default PouleClassement
import { useEffect, useState } from "react";
import CompetitionList from "../../components/CompetitionList/CompetitionList";
import TitlePage from "../../components/TitlePage/TitlePage";
import { useNavigate } from "react-router-dom";
import fetchAPI from "../../utils/fetch";
import Loader from "../../components/Loader/Loader";

const Home = () => {

    const navigate = useNavigate();
    const [competitions, setCompetitions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Load competitions
     */
    useEffect(() => {

        setIsLoading(true);

        (async () => {

            try {

                const request = await fetchAPI('GET', '/competition/getall-player', {});

                if(request.status !== 200) throw new Error();

                setCompetitions(request.json.data.competitions);
                setIsLoading(false);

            }
            catch(e) {
                navigate('/auth');
                return;
            }

        })();

    }, [navigate]);

    return (
        <>
            <Loader isLoading={isLoading} />
            <TitlePage title="A la une" subtitle="en ce moment" />
            <CompetitionList competitions={competitions.slice(1)} brand={competitions[0] ? competitions[0] : null} />
        </>
    )

}

export default Home;
import { useEffect, useState } from "react";
import ProfileView from "../../components/ProfileView/ProfileView";
import TitlePage from "../../components/TitlePage/TitlePage";
import fetchAPI from "../../utils/fetch";
import { useNavigate } from "react-router-dom";

const Profile = () => {

    const navigate = useNavigate();
    const [player, setPlayer] = useState(null);

    useEffect(() => {

        (async () => {

            try {

                const request = await fetchAPI('GET', '/player', {});

                if(request.status !== 200) {
                    navigate('/');
                    return;
                }

                setPlayer(request.json.data.player);


            }
            catch(e) {

            }

        })();

    }, [navigate])

    return (

        player &&

        <>
            <TitlePage title="Visualiser" subtitle="mon profil" />
            <br />
            
            <ProfileView player={player} />

        </>
    )

}

export default Profile;
import styled from "styled-components";
import TitlePage from "../components/TitlePage/TitlePage";
import RegisterForm from "../components/RegisterForm/RegisterForm";

const ConfirmationRegisterWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;

    & > div {
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:column;
    }

    & > div > ion-icon {
        font-size:100px;
        color:#2ecc71;
    }

    & > div > h4 {
        margin:10px;
        font-size:28px;
        color:#2ecc71;
    }

    & > div > h5 {
        margin:0 40px;
        font-size:18px;
        text-align:center;
    }

`

const ConfirmationRegister = () => {

    return (

        <ConfirmationRegisterWrapper>

            <div>
                <ion-icon name="checkmark-done-outline"></ion-icon>
                <h4>C'est fait</h4>
                <h5>Vérifiez vos emails pour valider votre compte</h5>
            </div>

        </ConfirmationRegisterWrapper>

    )

}

export default ConfirmationRegister;
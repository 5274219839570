import styled from "styled-components";
import ScoreInput from "./ScoreInput/ScoreInput";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    border-radius:8px;
    color:white;
    background:#2c3e50;
    margin:10px;
    display:flex;
    justify-content:space-between;
    align-items:center;

    & > div.teamA {
        width:25%;
        padding:0 0 0 5px;
        font-size:12px;
        overflow:hidden;
    }

    & > div.teamB {
        width:25%;
        text-align:right;
        padding:0 5px 0 0;
        overflow:hidden;
        font-size:12px;
    }

    & > div.scores {
        width:50%;
        display:flex;
        justify-content:center;
        align-items:center;
    }

`

const GameBlock = (props) => {

    return (

        <StyledDiv>
            <div className="teamA">{props.g.teamAName}</div>
            <div className="scores">
                { props.scores.scoresTeamA.map((s, i) => (
                    <ScoreInput score={s} team="A" scoreIndex={i} gameId={props.g.gameId} onUpdateScores={props.onUpdateScores} />
                )) }
                -
                { props.scores.scoresTeamB.map((s, i) => (
                    <ScoreInput score={s} team="B" scoreIndex={i} gameId={props.g.gameId} onUpdateScores={props.onUpdateScores} />
                )) }
            </div>
            <div className="teamB">{props.g.teamBName}</div>
        </StyledDiv>

    )

}

export default GameBlock;
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div `

    width: 98%;
    position:relative;
    background-color: white;
    margin-bottom: 18px;
    margin-top: 8px;
    margin-left:1%;
    border-radius: 12px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    & > div.logo {
        margin:10px;
    }

    & > div.logo > img {
        width:60px;
    }

    & > div.info {
        margin:16px;
    }

    & > div.info > h1.title {
        font-size: 16px;
        color:black;
        margin: 2px 0 4px 0;
    }

    & > div.info > h2.pro {
        font-size:14px;
        color:#F1C40F;
        margin:0;
    }

    & > div.info > h3.details {
        font-size: 11px;
        color:grey;
        margin:10px 0 0 0;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const ProBlock = (props) => {

    const navigate = useNavigate();

    // Handlers

    const gotoPro = (id) => {
        navigate('/pro/' + id)
    }

    return (
        <StyledDiv onClick={() => gotoPro(props.pro.proId)}>
            <div className="logo">
                <img src="/logo-tdp.jpg" alt="logo-tdp" />
            </div>
            <div className="info">
                <h1 className="title">{props.pro.name}</h1>
            </div>
        </StyledDiv>
    );

}

export default ProBlock;
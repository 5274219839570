import { useEffect, useState } from "react";
import ProfileView from "../../components/ProfileView/ProfileView";
import TitlePage from "../../components/TitlePage/TitlePage";
import fetchAPI from "../../utils/fetch";
import { useNavigate } from "react-router-dom";
import EditProfileForm from "../../components/EditProfileForm/EditProfileForm";
import moment from "moment";
import playerValidator from "../../utils/validators/player";
import Loader from "../../components/Loader/Loader";

const ProfileUpdate = () => {

    const navigate = useNavigate();
    const [player, setPlayer] = useState(null);

    const [name, setName] = useState("");
    const [firstname, setFirstname] = useState("");
    const [email, setEmail] = useState("");
    const [birthDate, setBirthDate] = useState(new Date());
    const [phone, setPhone] = useState("");

    const [error, setError] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        (async () => {

            try {

                const request = await fetchAPI('GET', '/player', {});

                if(request.status !== 200) {
                    navigate('/');
                    return;
                }

                setPlayer(request.json.data.player);


            }
            catch(e) {

            }

        })();

    }, [navigate])

    useEffect(() => {

        if(!player) return;

        setName(player.name ? player.name : "");
        setFirstname(player.firstname ? player.firstname : "");
        setEmail(player.email ? player.email : "");
        setPhone(player.phone ? player.phone : "");
        setBirthDate(player.birthDate ? moment(player.birthDate).format('YYYY-MM-DD') : (new Date()));
        
    }, [player]);

    const onUpdateName = (e) => {
        setName(e.target.value);
        setError(null);
    }

    const onUpdateFirstname = (e) => {
        setFirstname(e.target.value);
        setError(null);
    }

    const onUpdateEmail = (e) => {
        setEmail(e.target.value);
        setError(null);
    }

    const onUpdatePhone = (e) => {
        setPhone(e.target.value);
        setError(null);
    }

    const onUpdateBirthDate = (e) => {
        setBirthDate(e.target.value);
        setError(null);
    }

    const onValidate = async () => {

        setIsLoading(true);

        const formattedDate = birthDate ? birthDate.split('-').reverse().join("/") : "";

        try {

            playerValidator(firstname, name, email, phone, formattedDate, "XXXXXXXXXX");

            const request = await fetchAPI('PUT', '/player/update-player', {
                player: {
                    name: name,
                    firstname: firstname,
                    phone: phone,
                    email: email,
                    birthdate: formattedDate
                }
            });

            if(request.status !== 200) {
                if(request.json.message === "ERR_RESOURCE_EXISTS") throw new Error("Email ou téléphone déja utilisé.");
                throw new Error("Une erreur est survenue.");
            }

            if(email !== player.email) {
                navigate('/confirmation');
                return;
            }
            navigate('/profile');
            return;

        }
        catch(e) {
            setIsLoading(false);
            setError(e.message);
        }

    }

    return (

        player &&

        <>
            <TitlePage title="Modifier" subtitle="mes informations" />
            <br />
            <Loader isLoading={isLoading} />
            <EditProfileForm error={error} name={name} birthDate={birthDate} firstname={firstname} email={email} phone={phone} onUpdateName={onUpdateName} onUpdateFirstname={onUpdateFirstname} onUpdateEmail={onUpdateEmail} onUpdatePhone={onUpdatePhone} onUpdateBirthDate={onUpdateBirthDate} onValidate={onValidate} />

        </>
    )

}

export default ProfileUpdate;
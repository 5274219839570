import styled from "styled-components";
import TitlePage from "../components/TitlePage/TitlePage";
import LoginForm from "../components/LoginForm/LoginForm";
import FixedImg from "../components/FixedImg/FixedImg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import fetchAPI from "../utils/fetch";
import ForgotForm from "../components/ForgotForm/ForgotForm";

const ForgotWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:calc(100% - 50px);
    height:calc(100% - 40px);
    margin:40px 25px 0 25px;

`

const Forgot = () => {

    const navigate = useNavigate();

    useEffect(() => {

        (async () => {

            const request = await fetchAPI("GET", "/authenticate/check/player", {});

            if(request.status === 200) {
                navigate('/');
                return;
            }

        })();

    }, [navigate]);

    return (

        <ForgotWrapper>

            <TitlePage title="Récupérez" subtitle="votre compte" />

            <ForgotForm />

            <FixedImg />

        </ForgotWrapper>

    )

}

export default Forgot;
import styled from "styled-components"
import GameBlock from "./GameBlock/GameBlock";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    margin:20px 0;
    width:95%;
    display:flex;
    flex-direction:column;


`

const GameList = (props) => {

    return (

        <StyledDiv>

            { props.games.map((g, i) => <GameBlock g={g} scores={props.scores[i]} onUpdateScores={props.onUpdateScores} />)}

        </StyledDiv>

    )

}

export default GameList;
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import EntryPoint from './pages/EntryPoint';
import Register from './pages/Register';
import LoggedIn from './pages/LoggedIn';
import Home from './pages/LoggedIn/Home';
import Login from './pages/Login';
import Inscriptions from './pages/LoggedIn/Inscriptions';
import Pros from './pages/LoggedIn/Pros';
import Profile from './pages/LoggedIn/Profile';
import ConfirmationRegister from './pages/ConfirmationRegister';
import VerifyPlayer from './pages/VerifyPlayer';
import Competition from './pages/LoggedIn/Competition';
import Logout from './pages/Logout';
import Engage from './pages/LoggedIn/Engage';
import ConfirmationTeam from './pages/ConfirmationTeam';
import ConfirmationAsk from './pages/ConfirmationAsk';
import Forgot from './pages/Forgot';
import Confidentiality from './pages/Confidentiality';
import Pro from './pages/LoggedIn/Pro';
import Live from './pages/LoggedIn/Live';
import ConfirmationGiveup from './pages/ConfirmationGiveup';
import ProfileUpdate from './pages/LoggedIn/ProfileUpdate';
import ProfileCredentials from './pages/LoggedIn/ProfileCredentials';
import Scorer from './pages/Scorer';
import ConfirmationScore from './pages/ConfirmationScore';

function App() {

  return (

    <BrowserRouter>

      <Routes>

        <Route path='/auth' element={<EntryPoint />} />
        <Route path='/scorer/:id' element={<Scorer />} />
        <Route path='/verify/:token' element={<VerifyPlayer />} />
        <Route path='/forgot' element={<Forgot />} />
        <Route path='/login' element={<Login />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/register' element={<Register />} />
        <Route path='/confidentiality' element={<Confidentiality />} />
        <Route path='/confirmation' element={<ConfirmationRegister />} />
        <Route path='/confirmation-team' element={<ConfirmationTeam />} />
        <Route path='/confirmation-ask' element={<ConfirmationAsk />} />
        <Route path='/confirmation-giveup' element={<ConfirmationGiveup />} />
        <Route path='/confirmation-score' element={<ConfirmationScore />} />

        <Route path="/" element={<LoggedIn />}>

          <Route index path='/' element={<Home />} />
          <Route path='/inscriptions' element={<Inscriptions />} />
          <Route path='/pros' element={<Pros />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/update-profile' element={<ProfileUpdate />} />
          <Route path='/update-credentials' element={<ProfileCredentials />} />
          <Route path='/competition/:id' element={<Competition />} />
          <Route path='/pro/:id' element={<Pro />} />
          <Route path='/engage/:id' element={<Engage />} />
          <Route path='/live/:id' element={<Live />} />

        </Route>

      </Routes>
    </BrowserRouter>
  )

}

export default App;

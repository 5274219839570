import { Link } from "react-router-dom";
import styled from "styled-components";

const ConfirmationTeamWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;

    & > div {
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:column;
    }

    & > div > ion-icon {
        font-size:100px;
        color:#2ecc71;
    }

    & > div > h4 {
        margin:10px;
        font-size:28px;
        color:#2ecc71;
    }

    & > div > h5 {
        margin:0 40px;
        font-size:18px;
        text-align:center;
    }

    & > div > a {
        text-decoration:none;
        color:inherit;
    }

    & > div > a > h6 {
        margin:20px;
        font-size:16px;
        color: #f1c40f;
    }

`

const ConfirmationTeam = () => {

    return (

        <ConfirmationTeamWrapper>

            <div>
                <ion-icon name="checkmark-done-outline"></ion-icon>
                <h4>C'est fait</h4>
                <h5>Votre équipe est inscrite pour cette compétition !</h5>
                <Link to="/"><h6>Retour aux compétitions</h6></Link>
            </div>

        </ConfirmationTeamWrapper>

    )

}

export default ConfirmationTeam;
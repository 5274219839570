import styled from "styled-components";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    font-weight:800;

    & > h1 {
        margin:0;
        color:#2c3e50;
    }

    & > h2 {
        margin:5px 0 0 0;
        color:#f1c40f;
    }

`

const TitlePage = (props) => {

    return (

        <StyledDiv>
            <h1>{props.title}</h1>
            <h2>{props.subtitle}</h2>
        </StyledDiv>

    )

}

export default TitlePage;
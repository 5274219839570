import styled from "styled-components";

const StyledButton = styled.button `

    position:fixed;
    bottom:80px;
    left:0;
    width:95%;
    margin:0 2.5%;
    height:60px;
    background:#f1c40f;
    border:0;
    border-radius:12px;
    font-weight:bold;
    color:black;

`

const RefreshButton = (props) => {

    return <StyledButton onClick={props.onClick}>Actualiser</StyledButton>

}

export default RefreshButton;
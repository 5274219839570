import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import fetchAPI from "../../utils/fetch";
import TitlePage from "../../components/TitlePage/TitlePage";
import EngageForm from "../../components/EngageForm/EngageForm";
import ErrorBadge from "../../components/ErrorBadge/ErrorBadge";
import teamValidator from "../../utils/validators/team";


const Engage = ({ match }) => {

    const {id} = useParams();
    const navigate = useNavigate();

    const [competition, setCompetition] = useState(null);
    const [name, setName] = useState("");
    const [level, setLevel] = useState(0);
    const [friends, setFriends] = useState([]);
    const [players, setPlayers] = useState([]);
    const [results, setResults] = useState([]);
    const [criteria, setCriteria] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {

        if(!id) {
            navigate('/');
            return;
        }

        (async () => {

            try {

                const request = await fetchAPI("GET", "/competition/get-player", {
                    competitionId: parseInt(id)
                });

                if(request.status !== 200) {
                    navigate('/');
                    return;
                }

                setCompetition(request.json.data.competition);

            }
            catch(e) {
                console.log(e);
            }

        })();

    }, [id, navigate])

    useEffect(() => {

        (async () => {
            
            try {

                const request = await fetchAPI("GET", "/player/getfriends-player", {});

                if(request.status !== 200) throw new Error("Une erreur est survenue.");

                setFriends(request.json.data.friends.map((p) => {
                    return {
                        playerId: p.playerId,
                        firstname: p.firstname,
                        name: p.name
                    }
                }));
                

            }
            catch(e) {
                console.log(e);
            }

        })();

    }, []);

    const addToPlayers = (player) => {
        const founded = players.filter((p) => p.playerId === player.playerId);
        if(founded.length !== 0) return;
        setPlayers([...players, player]);
        close();
    }

    const handleChangeCriteria = (e) => {
        setCriteria(e.target.value);
    }

    const handleChangeName = (e) => {
        setName(e.target.value);
        setError(null);
    }

    const handleChangeLevel = (e) => {
        setLevel(e.target.value);
        setError(null);
    }

    const handleValidate = async () => {

        try {

            teamValidator(name, level);

            if(players.length < competition.minPlayersByTeam - 1) throw new Error("Vous devez sélectionner au moins " + (competition.minPlayersByTeam - 1) + " joueurs en plus de vous" );
            if(players.length > competition.minPlayersByTeam - 1) throw new Error("Vous devez sélectionner moins de " + (competition.maxPlayersByTeam - 1) + " joueurs en plus de vous" );

            const request = await fetchAPI("POST", "/team/engage-player", {
                team: {
                    name: name,
                    levelId: level,
                    players: players.map((p) => p.playerId)
                }
            });

            if(request.status !== 200) throw new Error("Une erreur est survenue.");

            navigate('/confirmation-team');
            return;

        }
        catch(e) {
            setError(e.message);
        }

    } 

    const handleValidCriteria = () => {

        if(!criteria || criteria.length === 0) {
            setError("Veuillez remplir une valeur.");
            return;
        }

        (async () => {

            try {

                let treatedCriteria;

                if(!criteria.includes('@')) {
                    if(/^\s*\S+\s+\S+/.test(criteria.trim())) {
                        treatedCriteria = criteria.toLowerCase();
                    } else {
                        treatedCriteria = criteria.replace(/[^\d+]/g, '');
                        if(treatedCriteria.startsWith("0")) treatedCriteria = "+33" + treatedCriteria.substring(1)
                    }
                } else {
                    treatedCriteria = criteria.toLowerCase();
                }

                const request = await fetchAPI('GET', '/player/getbycriteria-player', {
                    criteria: treatedCriteria
                });

                if(request.status !== 200) throw new Error("Une erreur est survenue.");

                setResults(request.json.data.players.map((p) => {
                    return {
                        playerId: p.playerId,
                        firstname: p.firstname,
                        name: p.name,
                    }
                }).filter((r) => !players.some((p) => r.playerId === p.playerId)));

            }
            catch(e) {
                setError(e.message);
            }

        })();

    }

    useEffect(() => {
        console.log("RESULTS", results);
    }, [results]);

    const close = () => {
        setResults();
        setCriteria("");
    }

    const onRemovePlayer = (e, playerId) => {
        e.preventDefault();
        e.stopPropagation();
        setPlayers(players.filter((p) => p.playerId !== playerId));
    } 

    return (

        competition &&

        <>
            <TitlePage title="Engager" subtitle="une équipe" />
            <EngageForm onRemovePlayer={onRemovePlayer} levels={competition.levels} friends={friends} addToPlayers={addToPlayers} criteria={criteria} handleChangeCriteria={handleChangeCriteria} name={name} level={level} handleChangeName={handleChangeName} handleChangeLevel={handleChangeLevel} handleValidate={handleValidate} handleValidCriteria={handleValidCriteria} players={players} results={results} close={close} />
            <ErrorBadge error={error} />
        </>

    )

}

export default Engage;
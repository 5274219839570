import styled from "styled-components";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;

    & > div.game {
        background:white;
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin:3px 2%;
        border-radius:5px;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    }

    & > div.game > div {
        color:#2c3e50;
        font-size:12px;
        font-weight:600;
    }

    & > div.game > div:nth-child(2) {
        width:50%;
        text-align:center;
    }

    & > div.game > div:nth-child(2) > span {
        background:white;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
        padding:6px;
        margin:0 6px;
        border-radius:6px;
    }

    & > div.game > div:last-child {
        width:25%;
        text-align:right;
        padding:8px 8px 8px 0;
        overflow:hidden;
    }

    & > div.game > div:first-child {
        width:25%;
        text-align:left;
        padding:8px 0 8px 8px;
    }

    & > div.game.active {
        background:#2c3e50;
    }

    & > div.game.active > div:first-child, & > div.game.active > div:last-child {
        color:white;
    }

`

const GameList = (props) => {

    return (

        props.games &&

        <StyledDiv>

            { props.games.map((g) => (
                <div className={"game " + ((g.teamAName === props.teamName || g.teamBName === props.teamName) && " active")}>
                    <div>{ g.teamAName }</div>
                    <div>
                        { g.scoresTeamA.map((s) => <span>{s}</span>) }
                        -
                        { g.scoresTeamB.map((s) => <span>{s}</span>) }
                    </div>
                    <div>{ g.teamBName }</div>
                </div>
            )) }

        </StyledDiv>

    )

}

export default GameList;
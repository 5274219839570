import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    margin-bottom: 15px;
    border-radius:12px;
    width:98%;
    margin-left:1%;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background-color:white;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);

    & > img {
        width:100px;
        margin:20px 0;
    }

    & > h4 {
        font-size:18px;
        margin:0 0 20px 0;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const ProBrand = (props) => {

    return (

        <StyledDiv>
            <img src="/logo-tdp.jpg" alt="logo-pro" />
            <h4>{props.pro.name}</h4>
        </StyledDiv>

    );

}

export default ProBrand;
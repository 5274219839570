import styled from "styled-components";

const StyledButton = styled.button `

    position:relative;
    top:0;
    left:0;
    width:95%;
    margin:20px 2.5%;
    border:0;
    height:60px;
    background:rgba(231, 76, 60,0.4);
    border-radius:12px;
    color:#e74c3c;
    font-weight:bold;

`

const ReturnButton = (props) => {

    return (
        <StyledButton onClick={props.onClick}>{props.title}</StyledButton>
    )

}

export default ReturnButton;
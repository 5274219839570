import styled from "styled-components";

const StyledInput = styled.input `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:40px;
    background:#ecf0f1;
    border:0;
    margin:2px 0 14px 0;
    border-radius:8px;
    padding:0 0 0 10px;
    outline:0;
    font-weight:500;

    &.small {
        height:30px;
        width:90%;
        margin:5px 5%;
    }

`

const Input = (props) => {

    return <StyledInput className={props.small && "small"} type={props.type ? props.type : "text"} placeholder={props.placeholder ? props.placeholder : ""} onChange={props.onChange ? props.onChange : () => {}} value={props.value ? props.value : ""} inputMode={props.inputMode} autoCapitalize={props.autoCapitalize} />;
}

export default Input;
import styled from "styled-components";

const StyledSpan = styled.span `

    color:#e74c3c;
    font-weight:bold;
    font-size:14px;

`

const Error_ = (props) => {

    return props.error && <StyledSpan>{props.error}</StyledSpan>

}

export default Error_;
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Logout = () => {

    const [cookies, setCookies] = useCookies(['playerAuthorization']);
    const navigate = useNavigate();

    useEffect(() => {

        setCookies('playerAuthorization', null);
        navigate('/');
        return;


    }, [setCookies, navigate])

    return "";

}

export default Logout;
import styled from "styled-components";
import TitlePage from "../components/TitlePage/TitlePage";
import LoginForm from "../components/LoginForm/LoginForm";
import RegisterForm from "../components/RegisterForm/RegisterForm";
import FixedImg from "../components/FixedImg/FixedImg";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import fetchAPI from "../utils/fetch";

const RegisterWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:calc(100% - 50px);
    height:calc(100% - 40px);
    margin:40px 25px 0 25px;
    overflow:auto;

`

const Register = () => {

    const navigate = useNavigate();

    useEffect(() => {

        (async () => {

            const request = await fetchAPI("GET", "/authenticate/check/player", {});

            if(request.status === 200) {
                navigate('/');
                return;
            }

        })();

    }, [navigate]);

    return (

        <RegisterWrapper>

            <TitlePage title="Créez" subtitle="votre compte" />

            <RegisterForm />

            <FixedImg />

        </RegisterWrapper>

    )

}

export default Register;
import styled from "styled-components";
import Input from "../Input/Input";
import Button from "../Button/Button";
import ErrorBadge from "../ErrorBadge/ErrorBadge.jsx";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;

`

const EditCredentialsForm = (props) => {

    return (

        <StyledDiv>
            <Input type="password" value={props.password} onChange={props.onUpdatePassword} placeholder="Nouveau mot de passe" />
            <Input type="password" value={props.passwordCf} onChange={props.onUpdatePasswordCf} placeholder="Confirmez votre nouveau mot de passe" />
            <Button title="Enregistrer" onClick={props.onValidate} />
            <ErrorBadge error={props.error} />
        </StyledDiv>

    )

}

export default EditCredentialsForm;
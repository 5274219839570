import styled from "styled-components";
import competitionModelConverter from "../../utils/modelConverters/competition";
import API_HOST_URL from "../../utils/apiHost";

const StyledDiv = styled.div `

    width:100%;
    border-radius:12px;
    background-image:url('/blur.jpg');
    background-size:cover;
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:space-between;

    & > div.profiles {
        display:flex;
        justify-content:center;
        padding:30px 0;
    }

    & > div.info {
        padding:0 0 20px 0;
    }

    & > div.info > h4 {
        margin:0;
        padding:0;
        width:100%;
        text-align:center;
        font-style:italic;
        text-transform:uppercase;
        color:white;
        font-weight:800;
        font-size:28px;
    }

    & > div.info > h5 {
        margin:0;
        padding:0;
        width:100%;
        text-align:center;
        font-style:italic;
        text-transform:uppercase;
        color:#f1c40f;
        font-weight:800;
        font-size:12px;
    }

`

const PlayerPP = styled.div `

    width:80px;
    height:80px;
    margin:0 8px;
    background-image:url(${props => API_HOST_URL + "/player/getpp?playerId=" + props.playerId});
    background-size:cover;
    border-radius:100%;

`

const TeamIllustration = (props) => {

    return (

        <StyledDiv>

            <div className="profiles">
                { props.team.players.map((p) => <PlayerPP playerId={p.playerId}></PlayerPP>) }
            </div>

            <div className="info">
                <h4>{ props.team.name }</h4>
                <h5>{ competitionModelConverter.getFormattedDate(props.competition) } - { props.competition.name }</h5>
            </div>

        </StyledDiv>

    )

}   

export default TeamIllustration;
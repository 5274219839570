import styled from "styled-components";
import { useEffect, useState } from "react";
import PhaseView from "./PhaseView/PhaseView"

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:98%;
    margin:10px 1%;
    padding:0 0 10px 0;
    border-radius: 12px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);

    & > div.tournb {
        position:relative;
        top:0;
        left:0;
        width:100%;
        background:#2c3e50;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding:8px 14px;
        font-size:14px;
        font-weight:500;
        color:white;
    }

    & > div.tournb > span {
        color:#f1c40f;
        padding:0 10px;
    }

`

const FinalTourViewer = (props) => {

    const [phases, setPhases] = useState([]);

    useEffect(() => {

        if(!props.tour) return;
        if(!props.tour.isFinal) return;

        const phases_ = [];
        
        for(const p of props.tour.poules) {
            for(const g of p.games) {
                if(g.teamAName === props.teamName || g.teamBName === props.teamName) {
                    phases_.push(p);
                }
            }
        }

        setPhases(phases_);

    }, [props.teamName, props.tour])

    return (

        phases && phases.length !== 0 &&

        <StyledDiv>

            <div className="tournb">{ props.tour.name }</div>

            { phases.map((phase) => <PhaseView phase={phase} teamName={props.teamName} />) }

            {/* <PouleClassement formattedClassement={poule.formattedClassement} teamId={props.teamId} />

            <GameList games={poule.games} />

            <TourClassement formattedClassement={props.tour.formattedClassement} teamId={props.teamId} /> */}

        </StyledDiv>

    )

}

export default FinalTourViewer;
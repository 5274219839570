import styled from "styled-components";
import PouleClassement from "./PouleClassement/PouleClassement";
import GameList from "../GameList/GameList";
import { useEffect, useState } from "react";
import TourClassement from "./TourClassement/TourClassement";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:98%;
    margin:10px 1%;
    padding:0 0 10px 0;
    border-radius: 12px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);

    & > div.tournb {
        position:relative;
        top:0;
        left:0;
        width:100%;
        background:#2c3e50;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding:8px 14px;
        font-size:14px;
        font-weight:500;
        color:white;
    }

    & > div.tournb > span {
        color:#f1c40f;
        padding:0 10px;
    }

`

const TourViewer = (props) => {

    const [poule, setPoule] = useState(null);

    useEffect(() => {

        if(!props.tour) return;
        if(props.tour.isFinal) return;
        
        for(const p of props.tour.poules) {
            for(const c of p.formattedClassement) {
                console.log(c.teamId, props.teamId);
                if(c.teamId === parseInt(props.teamId)) {
                    setPoule(p);
                    return;
                }
            }
        }

    }, [props.teamId, props.tour])

    return (

        poule && props.teamId && poule.games && poule.formattedClassement &&

        <StyledDiv>

            <div className="tournb">{ props.tour.name }<span>Poule { poule.nbPoule + " - Terrain : " + poule.field }</span></div>

            <PouleClassement formattedClassement={poule.formattedClassement} teamId={props.teamId} />

            <GameList games={poule.games} teamName={props.teamName} />

            <TourClassement formattedClassement={props.tour.formattedClassement} teamId={props.teamId} />

        </StyledDiv>

    )

}

export default TourViewer;
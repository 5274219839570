const getFormattedPouleTitle = (nbPoule, isFinal) => {
    if(isFinal) {
        if(nbPoule === 1) return "Finale";
        if(nbPoule === 2) return "1/2 finale";
        if(nbPoule === 4) return "1/4 finale";
        if(nbPoule === 8) return "1/8 finale";
    }
    return "Poule " + nbPoule;
}

const pouleModelConverter = {
    getFormattedPouleTitle
}

export default pouleModelConverter;
import styled from "styled-components";
import Input from "../Input/Input";
import Button from "../Button/Button";
import ErrorBadge from "../ErrorBadge/ErrorBadge.jsx";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;

`

const EditProfileForm = (props) => {

    return (

        <StyledDiv>
            <Input type="text" value={props.firstname} onChange={props.onUpdateFirstname} placeholder="Votre prénom" />
            <Input type="text" value={props.name} onChange={props.onUpdateName} placeholder="Votre nom" />
            <Input type="email" value={props.email} onChange={props.onUpdateEmail} placeholder="Votre email" />
            <Input type="tel" inputMode="numeric" value={props.phone} onChange={props.onUpdatePhone} placeholder="Votre numéro de téléphone" />
            Votre date de naissance :<Input type="date" placeholder="Votre date de naissance" onChange={props.onUpdateBirthDate} value={props.birthDate} />
            <Button title="Enregistrer" onClick={props.onValidate} />
            <ErrorBadge error={props.error} />
        </StyledDiv>

    )

}

export default EditProfileForm;
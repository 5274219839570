import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledButton = styled.button `

    position:relative;
    top:0;
    left:0;
    background:#2ecc71;
    color:white;
    width:100%;
    border:0;
    height:60px;
    border-radius:12px;
    font-size:16px;
    font-weight:bold;

`

const AddTeamButton = (props) => {

    const navigate = useNavigate();

    const handleEngage = (competitionId) => {
        navigate('/engage/' + competitionId);
    }

    return <StyledButton onClick={() =>handleEngage(props.competitionId)}>Engager une équipe</StyledButton>

}

export default AddTeamButton;
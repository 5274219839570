import styled from "styled-components";
import Input from "../Input/Input.jsx";
import Button from "../Button/Button.jsx";
import Error from "../ErrorBadge/ErrorBadge.jsx";
import { useState } from "react";
import fetchAPI from "../../utils/fetch.js";
import { useCookies } from "react-cookie";
import { useNavigate, Link } from "react-router-dom";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    margin:30px 0;

    & > a.lk {
        color:#2c3e50;
        text-decoration:none;
        font-weight:500;
        font-size:14px;
        display:block;
    }

`

const ForgotForm = () => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleAsk = async () => {

        if(!email) {
            setError("Veuillez remplir votre email.");
            return;
        }

        try {

            const request = await fetchAPI("POST", "/authenticate/asknpsswd/player", {
                email: email
            });

            if(request.status !== 200) throw new Error();

            navigate('/confirmation-ask');
            return;

        }
        catch(e) {
            setError("Une erreur est survenue");
        }

    }

    const onUpdateEmail = (e) => {
        setEmail(e.target.value);
        setError(null);
    }

    return (

        <StyledDiv>
            <Input type="text" placeholder="Votre email" onChange={onUpdateEmail} value={email} />
            <Button title="Demander un nouveau mot de passe" onClick={handleAsk} />
            <Error error={error} />
            <Link to="/login" className="lk">Je connais mon mot de passe</Link>
        </StyledDiv>

    )

}

export default ForgotForm;
import styled from "styled-components";
import TitlePage from "../components/TitlePage/TitlePage";
import FixedImg from "../components/FixedImg/FixedImg";

const ConfidentialityWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:calc(100% - 50px);
    height:calc(100% - 40px);
    margin:40px 25px 0 25px;
    overflow:auto;

    & > h4 {
        font-size:24px;
        margin: 20px 0 5px 0;
    }

    & > p, & > ul > li {
        font-size:14px;
        text-align:justify;
    }

`

const Confidentiality = () => {

    return (

        <ConfidentialityWrapper>

            <TitlePage title="Politique" subtitle="de confidentialité" />

            <h4>Introduction</h4>

            <p>Nous prenons la protection de vos données personnelles très au sérieux. Cette politique de confidentialité a pour but de vous informer de la manière dont nous collectons, utilisons et protégeons vos informations personnelles lorsque vous utilisez notre application.</p>

            <h4>Responsable</h4>
            <p>Le responsable du traitement de vos données est l'entreprise Digimeo Solutions (SIRET 88793503900016), dirigée par Monsieur PAUL BARBARIN (personne physique). Entreprise domiciliée au 34 TER Rue Pierre Rambaud, 33600 Pessac, FRANCE</p>
            <p>Le responsable est contactable par téléphone (+33 7 86 18 26 56) ou par email (rgpd@digimeo-solutions.fr)</p>

            <h4>Données personnelles collectées</h4>

            <p>Nous ne collectons uniquement que les données que vous entrez de manière volontaire dans l'application, à savoir :</p>

            <ul>
                <li>Votre nom</li>
                <li>Votre prénom</li>
                <li>Votre numéro de téléphone</li>
                <li>Votre adresse mail</li>
                <li>Votre date de naissance</li>
            </ul>

            <p>Votre mot de passe est stocké de manière totalement chiffrée dans nos bases de données.</p>

            <h4>Finalité du traitement</h4>

            <p>Les données collectées ne sont collectées que dans le but de pouvoir vous fournir les services proposés par l'application Neoscore Joueur. Aucune donnée personnelle n'est collectée dans le but d'une utilisation commerciale ou à des fins publicitaires. La collecte de vos données ne se limite qu'à un but purement fonctionnel. </p>

            <h4>Base légale du traitement</h4>

            <p>Le traitement de vos données personnelles est basé sur :</p>

            <ul>
                <li>Votre consentement explicite lors de la création de votre compte.</li>
                <li>La nécessité contractuelle pour la gestion de votre compte utilisateur.</li>
                <li>Le respect de nos obligations légales.</li>
            </ul>

            <h4>Partage des données</h4>

            <p>Vos données personnelles ne sont partagées avec aucun tiers, sauf dans les cas suivants :</p>

            <ul>
                <li>Si nous sommes tenus de le faire en vertu de la loi ou d'une demande judiciaire.</li>
                <li>Avec des prestataires de services de confiance qui nous aident à gérer notre application, sous réserve qu'ils respectent cette politique de confidentialité et les lois en vigueur sur la protection des données.</li>
            </ul>

            <h4>Durée de conservation des données</h4>

            <p>Nous conservons vos données personnelles aussi longtemps que nécessaire pour les finalités décrites ci-dessus, sauf si une période de conservation plus longue est requise ou permise par la loi.</p>

            <h4>Vos droits</h4>

            <p>Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez des droits suivants concernant vos données personnelles :</p>

            <ul>
                <li>Droit d'accès : Vous pouvez demander à accéder à vos données personnelles.</li>
                <li>Droit de rectification : Vous pouvez demander la correction de vos données personnelles inexactes ou incomplètes.</li>
                <li>Droit à l'effacement : Vous pouvez demander la suppression de vos données personnelles.</li>
                <li>Droit à la limitation du traitement : Vous pouvez demander la limitation du traitement de vos données personnelles.</li>
                <li>Droit d'opposition : Vous pouvez vous opposer au traitement de vos données personnelles.</li>
                <li>Droit à la portabilité : Vous pouvez demander la transmission de vos données personnelles à un autre responsable du traitement.</li>
                <li>Droit de retirer votre consentement : Vous pouvez retirer votre consentement à tout moment.</li>
            </ul>

            <p>Pour exercer ces droits, veuillez nous contacter à l'adresse suivante : [rgpd@digimeo-solutions.fr]</p>

            <h4>Sécurité des Données</h4>

            <p>Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos données personnelles contre la perte, l'utilisation abusive, l'accès non autorisé, la divulgation, l'altération ou la destruction.</p>

            <h4>Modifications de la Politique de Confidentialité</h4>

            <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page et, si les changements sont significatifs, nous vous en informerons par email ou via notre application.</p>

            <h4>Contact</h4>

            <p>Pour toute question sur cette politique, vous pouvez contacter le responsable dont les coordonnées sont présentes en section 1 en haut de page.</p>

            <p>M.A.J 16/07/2024</p>

            <FixedImg notfixed={true} textual={true} />

        </ConfidentialityWrapper>

    )

}

export default Confidentiality;
import styled from "styled-components";

const StyledButton = styled.button `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:40px;
    background:#f1c40f;
    color:white;
    border:0;
    margin:8px 0 14px 0;
    border-radius:8px;
    padding:0 0 0 10px;
    font-weight:500;

`

const Button = (props) => {

    return <StyledButton onClick={props.onClick ? props.onClick : () => {}}>{props.title ? props.title : "Valider"}</StyledButton>;
}

export default Button;
import styled from "styled-components";
import Input from "../Input/Input.jsx";
import Button from "../Button/Button.jsx";
import ErrorBadge from "../ErrorBadge/ErrorBadge.jsx";
import { useState } from "react";
import playerValidator from "../../utils/validators/player.js";
import fetchAPI from "../../utils/fetch.js";
import { useNavigate, Link } from "react-router-dom";
import Loader from "../Loader/Loader.jsx";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    margin:30px 0;

    & > a.lk {
        color:#2c3e50;
        text-decoration:none;
        font-weight:500;
        font-size:14px;
        display:block;
    }

    & > p.confidentiality {
        font-size:14px;
    }

    & > p.confidentiality > a {
        color:#f1c40f;
        font-weight:bold;
        text-decoration:none;
    }

`

const RegisterForm = () => {

    const [firstname, setFirstname] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [phone, setPhone] = useState("");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleChangeFirstname = (e) => {
        setFirstname(e.target.value);
        setError(null);
    }

    const handleChangeName = (e) => {
        setName(e.target.value);
        setError(null);
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        setError(null);
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
        setError(null);
    }

    const handleChangePassword2 = (e) => {
        setPassword2(e.target.value);
        setError(null);
    }

    const handleChangeBirthDate = (e) => {
        setBirthdate(e.target.value);
        setError(null);
    }

    const handleChangePhone = (e) => {
        setPhone(e.target.value);
        setError(null);
    }

    const handleRegister = async () => {

        setIsLoading(true);

        const formattedDate = birthdate ? birthdate.split('-').reverse().join("/") : "";

        try {

            let treatedPhone = phone.replace(/[^\d+]/g, '');
            if(treatedPhone.startsWith("0")) treatedPhone = "+33" + treatedPhone.substring(1)

            playerValidator(firstname, name, email, treatedPhone, formattedDate, password);

            if(password !== password2) {
                setError("Vos mots de passes ne correspondent pas.");
                return;
            }

            const request = await fetchAPI('POST', '/player/subscribe-player', {
                player: {
                    name: name.trim().charAt(0).toUpperCase() + name.trim().slice(1).toLowerCase(),
                    firstname: firstname.trim().charAt(0).toUpperCase() + firstname.trim().slice(1).toLowerCase(),
                    email: email.trim().toLowerCase(),
                    password: password,
                    phone: treatedPhone,
                    birthdate: formattedDate
                }
            });

            if(request.status !== 200 && request.json.message === "ERR_RESOURCE_EXISTS") throw new Error("Vous avez déja un compte.");
            if(request.status !== 200) throw new Error("Une erreur est survenue.");

            navigate('/confirmation');
            return;

        }
        catch(e) {
            setIsLoading(false);
            setError(e.message);
        }

    }

    return (

        <StyledDiv>
            {isLoading && <Loader isLoading={isLoading} />}
            <Input type="text" placeholder="Votre prénom" value={firstname} onChange={handleChangeFirstname} />
            <Input type="text" placeholder="Votre nom" value={name} onChange={handleChangeName} />
            <Input type="email" placeholder="Votre email" value={email} onChange={handleChangeEmail} />
            <Input type="password" placeholder="Votre mot de passe" value={password} onChange={handleChangePassword} />
            <Input type="password" placeholder="Confirmez votre mot de passe" value={password2} onChange={handleChangePassword2} />
            Votre date de naissance :<Input type="date" placeholder="Votre date de naissance" onChange={handleChangeBirthDate} value={birthdate} />
            <Input type="tel" inputMode="numeric" placeholder="Votre numéro de téléphone" value={phone} onChange={handleChangePhone} />
            <Button title="S'inscrire" onClick={handleRegister} />
            <p className="confidentiality">En créant un compte, vous acceptez notre <Link to="/confidentiality">politique de confidentialité</Link></p>
            <ErrorBadge error={error} />
            <Link to="/login" className="lk">J'ai déja un compte</Link>
        </StyledDiv>

    )

}

export default RegisterForm;
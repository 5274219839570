import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import competitionModelConverter from "../../../utils/modelConverters/competition";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    margin-bottom: 15px;
    border-radius:12px;
    width:100%;
    overflow:hidden;
    display:flex;
    background-size:cover;
    background-image: url('https://media.sudouest.fr/11828964/1200x-1/20220731175033-dsc482.jpg');
    height:200px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }

    & > div.datewrapper {

        position:absolute;
        top: 0;
        right: 0;
        display:flex;
        flex-direction:column;
        background-color: white;
        margin-top: 20px;
        z-index:2;
        margin-right: 20px;
        width: 60px;
        height: 60px;
        border-radius: 50px;
        justify-content: center;
        align-items:center;

    }

    & > div.datewrapper > span.dateday {    
        font-size: 22px;
        margin-top: -3px;
        font-weight:bold;
    }

    & > div.datewrapper > span.datemonth {    
        font-size: 12px;
        margin-top: -3px;
        color:#F1C40F;
        font-weight:bold;
    }

    & > div.info {
        position:absolute;
        left:0;
        z-index:2;
        bottom:0;
        margin:12px;
    }

    & > div.info > h1.title {
        margin:0;
        font-size:20px;
        color:white;
    }

    & > div.info > h2.pro {
        margin:2px 0 0 0;
        font-size:14px;
        color:#F1C40F;
    }

    & > div.info > h3.details {
        margin:4px 0 0 0;
        font-size:10px;
        color:#ecf0f1;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const BrandCompetition = (props) => {

    const navigate = useNavigate();

    const handleNavigate = (id) => {
        if(id === null) return 0;
        navigate("/competition/" + id);
    }

    return (

        <StyledDiv onClick={() => handleNavigate(props.competition.competitionId)}>
            <div className="datewrapper">
                <span className="dateday">{ competitionModelConverter.formatDateNumberAsString(props.competition) }</span>
                <span className="datemonth">{ competitionModelConverter.formatMonthAbbrAsString(props.competition) }</span>
            </div>
            <div className="info">
                <h1 className="title">{props.competition.name}</h1>
                <h2 className="pro">{props.competition.pro.name}</h2>
                <h3 className="details">{props.competition.sport.name} - {props.competition.place}</h3>
            </div>
        </StyledDiv>

    );

}

export default BrandCompetition;
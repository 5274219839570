const moment = require('moment');

const months = [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];

const getFormattedDate = (competition) => {
    if(!competition) return "Non chargée";
    const date = moment(competition.dte);
    return date.format('DD/MM/YYYY');
}

const getFormattedDateTime = (competition) => {
    if(!competition) return "Non chargée";
    const date = moment(competition.dte);
    return date.format('DD/MM/YYYY à HH:mm');
}

const getOnlineSubscriptionStatus = (competition) => {
    return {
        text: competition.onlineSub && !(competition.limitDteOnline && competition.limitDteOnline > (new Date())) ? "Ouvertes" : "Fermées",
        className: competition.onlineSub ? "success" : "error",
    }
}

const getOnlinePaymentStatus = (competition) => {
    return {
        text: competition.onlinePay ? "Ouvert" : "Fermé",
        className: competition.onlinePay ? "success" : "error",
    }
}

export const formatMonthAbbrAsString = (competition) => {
    return months[parseInt(competition.dte.split("T")[0].split('-')[1]) - 1].substring(0,3);
}

export const formatDateNumberAsString = (competition) => {
    return competition.dte.split("T")[0].split('-')[2];
}

const competitionModelConverter = {
    getFormattedDate,
    getFormattedDateTime,
    getOnlineSubscriptionStatus,
    getOnlinePaymentStatus,
    formatMonthAbbrAsString,
    formatDateNumberAsString
}

export default competitionModelConverter;
import { useNavigate } from "react-router-dom";
import TitlePage from "../../components/TitlePage/TitlePage";
import { useEffect, useState } from "react";
import fetchAPI from "../../utils/fetch";
import TeamList from "../../components/TeamList/TeamList";
import Loader from "../../components/Loader/Loader";

const Inscriptions = () => {

    const navigate = useNavigate();
    const [teams, setTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Load teams
     */
    useEffect(() => {

        setIsLoading(true);

        (async () => {

            try {

                const request = await fetchAPI('GET', '/player/getteams-player', {});

                if(request.status !== 200) throw new Error();

                setTeams(request.json.data.teams);

                setIsLoading(false);

            }
            catch(e) {
                navigate('/auth');
                return;
            }

        })();

    }, [navigate]);

    return (
        <>
            <Loader isLoading={isLoading} />
            <TitlePage title="Toutes vos" subtitle="inscriptions" />
            <TeamList teams={teams} />
        </>
    )

}

export default Inscriptions;
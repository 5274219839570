import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import fetchAPI from "../../utils/fetch";
import API_HOST_URL from "../../utils/apiHost";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;

    & > div.heading {
        position:relative;
        top:0;
        left:0;
        width:100%;
        padding:0 0 16px 0;
        display:flex;
        align-items:center;
        justify-content:space-between;
        border-bottom:1px solid #ecf0f1;
    }

    & > div.heading > div.info_ > h3 {
        margin:0 0 4px 0;
        font-size:30px;
    }

    & > div.heading > div.info_ > h4 {
        margin:0;
        color:grey;
        font-size:14px;
    }

    & > div.heading > div.cover > img {
        width:100px;
        border-radius:100%;
    }

    & > div.menu {
        position:relative;
        top:0;
        left:0;
        width:100%;
        display:flex;
        flex-direction:column;
        margin:20px 0;
    }

    & > div.menu > a {
        text-decoration:none;
        color:grey;
        font-weight:500;
        margin:18px 0;
    }

    & > div.menu > a.danger {
        color:#e74c3c;
        font-weight:bold;
    }

    & > div.bottomLogout {
        position:fixed;
        bottom:68px;
        left:0;
        width:100%;
        height:60px;
        line-height:60px;
        color:black;
        font-weight:bold;
        padding:0 0 0 10px;
        background:rgba(231, 76, 60,0.3);
        font-size:16px;
        cursor:pointer;
    }

    & > div.bottomLogout > ion-icon {
        font-size:26px;
        transform:translateY(6px);
        padding:0 6px 0 0;
    }
 
`

const CoverPP = styled.div `

    width:100px;
    height:100px;
    border-radius:100%;
    background-image:url(${props => API_HOST_URL + "/player/getpp?playerId=" + props.playerId + "&refresh=" + props.refresh});
    background-size:cover;

`

const PPInput = styled.input `
    display:none;
`

const ProfileView = (props) => {

    const navigate = useNavigate();
    const ppref = useRef(null);

    const [refreshPP, setRefreshPP] = useState(0);

    const handleClickPP = () => {
        ppref.current.click();
      };

    const handleChangePP = async (event) => {

        const file = event.target.files[0];
        
        if(file) {
            
            const formData = new FormData();
            formData.append('pp', file);

            const request = await fetchAPI('PUT', '/player/changecover-player', formData);

            if(request.status !== 200) {
                console.log("Error");
            }

            setRefreshPP(refreshPP+1);

        }

      };

    return (

        props.player &&

        <StyledDiv>

            <div className="heading">
                
                <div className="info_">
                    <h3>{ props.player.firstname }</h3>
                    <h4>{ props.player.email }</h4>
                </div>

                <div className="cover">
                    <CoverPP playerId={props.player.playerId} refresh={refreshPP} onClick={handleClickPP}></CoverPP>
                    <PPInput type="file" accept="image/*" ref={ppref} onChange={handleChangePP} />
                </div>

            </div>

            <div className="menu">
                <Link to="/update-profile">Modifier mes informations</Link>
                <Link to="/update-credentials">Modifier mon mot de passe</Link>
                <Link to="/confidentiality">Politique de confidentialité</Link>
                <Link to="" onClick={() => window.alert("Pour supprimer votre compte, merci de nous contacter.")} className="danger">Clôturer mon compte</Link>
            </div>

            <div className="bottomLogout" onClick={() => navigate('/logout')}><ion-icon name="log-out-outline"></ion-icon>Déconnexion</div>

        </StyledDiv>

    )

}

export default ProfileView;
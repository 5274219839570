import styled from "styled-components";
import ProBlock from "./ProBlock/ProBlock";

const StyledDiv = styled.div `

    justify-content:center;
    align-items:center;
    margin:20px 0 0 0;
`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const ProList = (props) => {

    return (
        <StyledDiv>
            { props.pros.map((p) => (
                <ProBlock pro={p} key={p.proId} />
            ))}
        </StyledDiv>
    );

}

export default ProList;
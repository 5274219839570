import { useNavigate, useParams } from "react-router-dom";
import BrandCompetition from "../../components/CompetitionList/BrandCompetition/BrandCompetition";
import { useEffect, useState } from "react";
import fetchAPI from "../../utils/fetch";
import TitlePage from "../../components/TitlePage/TitlePage";
import InformationCompetition from "../../components/InformationCompetition/InformationCompetition";
import TeamBlock from "../../components/TeamList/TeamBlock/TeamBlock";
import AddTeamButton from "../../components/AddTeamButton/AddTeamButton";


const Competition = ({ match }) => {

    const {id} = useParams();
    const navigate = useNavigate();

    const [competition, setCompetition] = useState(null);
    const [showEngage, setShowEngage] = useState(false);
    const [teams, setTeams] = useState([]);

    useEffect(() => {

        if(!id) {
            navigate('/');
            return;
        }

        (async () => {

            try {

                const request = await fetchAPI("GET", "/competition/get-player", {
                    competitionId: parseInt(id)
                });

                if(request.status !== 200) {
                    navigate('/');
                    return;
                }

                setCompetition(request.json.data.competition);

                if(request.json.data.competition.onlineSub) {
                    if(!request.json.data.competition.limitDteOnline || (new Date(request.json.data.competition.limitDteOnline)) > (new Date())) {
                        if(request.json.data.competition.countTeams < request.json.data.competition.max || !request.json.data.competition.max) {
                            if(parseInt(request.json.data.competition.countTours) === 0) {
                                setShowEngage(true);
                            }
                        }
                    }
                }

            }
            catch(e) {
                console.log(e);
            }

        })();

    }, [id, navigate])

    useEffect(() => {

        if(!competition) return;

        (async () => {

            try {

                const request = await fetchAPI('GET', '/player/getteams-player', {});

                if(request.status !== 200) {
                    navigate('/');
                    return;
                }

                setTeams(request.json.data.teams.filter((t) => t.level.competition.competitionId === competition.competitionId));

            }
            catch(e) {

            }

        })();

    }, [competition, navigate])

    return (

        competition &&

        <>
            <TitlePage title="Informations" subtitle="sur la compétition" />
            <br />
            <BrandCompetition competition={competition} />
            <InformationCompetition competition={competition} />
            <br />
            { teams.length <= 1 ? (teams.length === 1 ? <TitlePage title="" subtitle="Votre équipe" /> : "") : <TitlePage title="" subtitle="Vos équipes" /> }
            <br />
            { teams.map((t) => <TeamBlock key={t.teamId} team={t} cancel={true} />)}
            { showEngage && <AddTeamButton competitionId={competition.competitionId} />}
        </>

    )

}

export default Competition;
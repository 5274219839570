import styled from "styled-components";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import fetchAPI from "../utils/fetch";
import NavigationBar from "../components/NavigationBar/NavigationBar";

const LoggedInWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;

    & > div.content {
        position:relative;
        top:0;
        left:0;
        width:calc(100% - 50px);
        height:calc(100% - 40px);
        margin:40px 25px 0 25px;
        height:calc(100% - 70px);
        overflow:auto;
    }

`

const LoggedIn = (props) => {

    const [cookies, setCookies] = useCookies(["playerAuthorization"]);
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        
        if(!cookies.playerAuthorization) {
            navigate('/auth');
        }

        (async () => {

            try {

                const request = await fetchAPI("GET", "/authenticate/check/player", {});

                if(request.status !== 200) {
                    navigate('/auth');
                    return;
                }
                setLoggedIn(true);

            }
            catch(e) {
                navigate('/auth');
                return;
            }

        })();

    }, [cookies.playerAuthorization, navigate])

    return (
        loggedIn &&
        <LoggedInWrapper>
            <div className="content">
                <Outlet />
            </div>
            <NavigationBar />
        </LoggedInWrapper>
    )

}

export default LoggedIn;
import { useEffect, useState } from "react";
import TitlePage from "../../components/TitlePage/TitlePage";
import fetchAPI from "../../utils/fetch";
import { useNavigate } from "react-router-dom";
import ProList from "../../components/ProList/ProList";
import Loader from "../../components/Loader/Loader";

const Pros = () => {

    const [pros, setPros] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        setIsLoading(true);

        (async () => {

            const request = await fetchAPI('GET', '/pro/getall-player', {});

            if(request.status !== 200) {
                navigate('/');
                return;
            }

            setPros(request.json.data.pros);
            setIsLoading(false);

        })();

    }, [navigate]);

    return (

        pros &&

        <>
            <Loader isLoading={isLoading} />
            <TitlePage title="Annuaire des" subtitle="organisateurs" />
            <br />
            <ProList pros={pros} />
        </>
    )

}

export default Pros;
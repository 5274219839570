import styled from "styled-components";

const StyledInput = styled.input `

    width:40px;
    height:40px;
    margin:5px;
    border-radius:5px;
    border:0;
    font-size:25px;
    font-weight:bold;
    text-align:center;

`

const ScoreInput = (props) => {

    return <StyledInput inputMode="numeric" type="text" value={props.score} onChange={(e) => props.onUpdateScores(props.team, props.scoreIndex, props.gameId, e)} />

}

export default ScoreInput;
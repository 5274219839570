import styled from "styled-components";
import pouleModelConverter from "../../../../utils/modelConverters/poule";
import GameList from "../../GameList/GameList";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:100%;

    & > h6 {
        margin:10px;
        font-size:14px;
    }

`

const PhaseView = (props) => {

    return (

        <StyledDiv>
            <h6>{pouleModelConverter.getFormattedPouleTitle(props.phase.nbPoule, true)}</h6>
            <GameList games={props.phase.games} teamName={props.teamName} />
        </StyledDiv>

    )

}

export default PhaseView;
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledButton = styled.button `

    position:relative;
    top:0;
    left:0;
    background:#2ecc71;
    color:white;
    width:100%;
    border:0;
    height:60px;
    border-radius:12px;
    font-size:16px;
    font-weight:bold;

    &.disabled {
        background:grey;
    }

`

const ValidateButton = (props) => {

    return <StyledButton className={props.disabled ? "disabled" : "enabled"} onClick={props.onClick}>{props.disabled ? props.text : "Valider"}</StyledButton>

}

export default ValidateButton;
import styled from "styled-components";
import TitlePage from "../components/TitlePage/TitlePage";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import ErrorBadge from "../components/ErrorBadge/ErrorBadge"
import fetchAPI from "../utils/fetch";
import Button from "../components/Button/Button";
import GameList from "../components/GameList/GameList";

const ScorerWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:calc(100% - 50px);
    height:calc(100% - 40px);
    margin:40px 25px 0 25px;
    overflow:auto;

    & > h4 {
        font-size:24px;
        margin: 20px 0 5px 0;
    }

    & > p, & > ul > li {
        font-size:14px;
        text-align:justify;
    }

`

const Scorer = ({match}) => {

    const {id} = useParams();
    const [poule, setPoule] = useState(null);
    const [nbSteps, setNbSteps] = useState(null);
    const [games, setGames] = useState([]);
    const [scoresGames, setScoresGames] = useState([]);

    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const onUpdateScores = (team, index, gameId, e) => {
        setError(null);
        setScoresGames(scoresGames.map((s) => {
            if(s.gameId !== gameId) return s;
            return {
                ...s,
                scoresTeamA: team !== "A" ? s.scoresTeamA : s.scoresTeamA.map((score, i) => {
                    return i === index ? e.target.value : score;
                }),
                scoresTeamB: team !== "B" ? s.scoresTeamB : s.scoresTeamB.map((score, i) => {
                    return i === index ? e.target.value : score;
                })
            }
        }))
    }

    const handleSave = async () => {

        for(const sg of scoresGames) {
            for(let i = 0; i < sg.scoresTeamA.length; i++) {
                sg.scoresTeamA[i] = parseInt(sg.scoresTeamA[i]);
                sg.scoresTeamB[i] = parseInt(sg.scoresTeamB[i]);
                if(isNaN(sg.scoresTeamA[i]) || isNaN(sg.scoresTeamB[i])) {
                    setError("Scores incorrects.");
                    return;
                }
            }
        }
        
        try {
            
            const request = await fetchAPI('PUT', '/poule/score', {
                scoresGames: scoresGames,
                pouleId: id
            });

            if(request.status !== 200) {
                navigate('/');
                return;
            }

            navigate('/confirmation-score');
            return;

        }
        catch(e) {
            setError("Une erreur est survenue.");
            return;
        }

    }

    useEffect(() => {

        if(!id) return;

        (async () => {

            try {

                const request = await fetchAPI("GET", '/poule/get', {
                    pouleId: parseInt(id)
                });

                if(request.status !== 200) {
                    navigate('/');
                    return;
                }

                setPoule(request.json.data.poule);
                setGames(request.json.data.poule.games);
                setNbSteps(request.json.data.nbSteps);

                let scoresGames = [];
                for(const g of request.json.data.poule.games) {
                    scoresGames = [...scoresGames, {
                        gameId: g.gameId,
                        scoresTeamA: g.scoresTeamA,
                        scoresTeamB: g.scoresTeamB,
                    }];
                }

                setScoresGames(scoresGames);

            }
            catch(e) {
                console.log(e);
            }

        })();

    }, [navigate, id]);

    return (

        poule && games &&

        <ScorerWrapper>

            <TitlePage title="Résultats" subtitle={poule.tourName + " - Poule " + poule.nbPoule} />

            <GameList games={games} scores={scoresGames} onUpdateScores={onUpdateScores} />
            <Button title="Enregistrer" onClick={handleSave} />
            <ErrorBadge error={error} />
        </ScorerWrapper>

    )

}

export default Scorer;
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import competitionModelConverter from "../../../utils/modelConverters/competition";

const StyledDiv = styled.div `

    width: 98%;
    position:relative;
    background-color: white;
    margin-bottom: 18px;
    margin-top: 8px;
    margin-left:1%;
    border-radius: 12px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    & > div.status {
        position:absolute;
        top:0;
        right:0;
        width:14px;
        height:14px;
        margin:5px;
        border-radius:50%;
    }

    & > div.status.opened {
        background:#2ecc71;
    }

    & > div.status.closed {
        background:#e74c3c;
    }

    & > div.info {
        margin-left: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    & > div.info > h1.title {
        font-size: 16px;
        color:black;
        margin: 2px 0 4px 0;
    }

    & > div.info > h2.pro {
        font-size:14px;
        color:#F1C40F;
        margin:0;
    }

    & > div.info > h3.details {
        font-size: 11px;
        color:grey;
        margin:10px 0 0 0;
    }

    & > div.date {
        margin-right:10px;
        text-align:right;
    }

    & > div.date > span.datetext {
        font-size:12px;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const CompetitionBlock = (props) => {

    const navigate = useNavigate();

    // Handlers

    const gotoCompetition = (id) => {
        navigate('/competition/' + id)
    }

    return (
        <StyledDiv onClick={() => gotoCompetition(props.competition.competitionId)}>
            <div className={"status " + (props.competition.onlineSub ? "opened" : "closed")}></div>
            <div className="info">
                <h1 className="title">{props.competition.name}</h1>
                <h2 className="pro">{props.competition.pro.name}</h2>
                <h3 className="details">{props.competition.sport.name} - {props.competition.place}</h3>
            </div>
            <div className="date">
                <span className="datetext">{competitionModelConverter.getFormattedDate(props.competition)}</span>
            </div>
        </StyledDiv>
    );

}

export default CompetitionBlock;
import styled from "styled-components";
import competitionModelConverter from "../../utils/modelConverters/competition";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    width:98%;
    margin:10px 1%;
    border-radius:12px;
    display:flex;
    flex-direction:column;
    padding:14px;

    & > div.element {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
    }

    & > div.element > div.tt {
        font-size:14px;
        font-weight:bold;
    }

    & > div.element > div.ct {
        font-size:14px;
        font-weight:normal;
    }

`

const InformationCompetition = (props) => {

    return (

        props.competition &&

        <StyledDiv>
            <div className="element">
                <div className="tt">Lieu</div>
                <div className="ct">{ props.competition.place }</div>
            </div>
            <div className="element">
                <div className="tt">Date</div>
                <div className="ct">{ competitionModelConverter.getFormattedDateTime(props.competition) }</div>
            </div>
            <div className="element">
                <div className="tt">Niveaux</div>
                <div className="ct">{ props.competition.levels.map((l) => l.name + " ") }</div>
            </div>
            <div className="element">
                <div className="tt">Inscription en ligne</div>
                <div className={"ct " + (competitionModelConverter.getOnlineSubscriptionStatus(props.competition).className)}>{ competitionModelConverter.getOnlineSubscriptionStatus(props.competition).text }</div>
            </div>
            <div className="element">
                <div className="tt">Paiement en ligne</div>
                <div className={"ct " + (competitionModelConverter.getOnlinePaymentStatus(props.competition).className)}>{ competitionModelConverter.getOnlinePaymentStatus(props.competition).text }</div>
            </div>
            <div className="element">
                <div className="tt">Equipes inscrites</div>
                <div className="ct">{ props.competition.countTeams }</div>
            </div>
            <div className="element">
                <div className="tt">Limite d'inscriptions en ligne</div>
                <div className="ct">{ props.competition.max }</div>
            </div>
        </StyledDiv>

    )

}

export default InformationCompetition;